import React, { useState, useEffect } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { getPortfolio } from '../api';
import { Portfolio, PortfolioResponse } from './Models';


const PortfolioPage: React.FC = () => {
  const [portfolio, setPortfolio] = useState<PortfolioResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const fetchPortfolio = async () => {
    setLoading(true);
    const data = await getPortfolio();
    setPortfolio(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchPortfolio();
  }, []);

  const columns: GridColDef[] = [
    
    { field: 'Name', headerName: 'Name', width: 200 },
    { field: 'Price', headerName: 'Current price', width: 150 },
    { field: 'Position', headerName: 'Position', width: 150 },
    { field: 'MarketValue', headerName: 'Market Value', width: 150, valueGetter: (value)=> Math.round(value * 100) / 100 },
    { field: 'Profit', headerName: 'Profit', width: 150,valueGetter: (value)=> Math.round(value * 100) / 100  },
    { field: 'AveragePrice', headerName: 'Average price', width: 150 ,valueGetter: (value)=> Math.round(value * 100) / 100 },

  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      {portfolio && Object.keys(portfolio).map((key) => {
        // Calculate the sum for MarketValue as an example
        const totalMarketValue = portfolio[key].reduce((sum, item) => sum + item.MarketValue, 0);

        return (
          <div key={key} style={{ marginBottom: '2rem' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6" gutterBottom>{key}</Typography>
              <Typography variant="subtitle1">Total Market Value: {totalMarketValue.toFixed(2)}</Typography>
            </Box>
            <DataGrid 
              getRowId={(row: Portfolio) => row.Id}
              rows={portfolio[key]}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              autoHeight
            />
          </div>
        );
      })}
    </Container>
  );
};

export default PortfolioPage;