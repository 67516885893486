import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navigation from './components/Navigation';
import Transactions from './pages/Transactions';
import Portfolio from './pages/Portfolio';
import BasicInfo from './pages/BasicInfo';
import { DataProvider } from './state/StockContext';
import { onAuthStateChanged } from 'firebase/auth';
import { useSelector, useDispatch } from 'react-redux';
import { auth } from './firebase';
import { RootState } from './redux/store';
import { saveUser, setLoading } from './redux/userSlice';
import Logout from './components/Logout';
import Login from './components/Login';
import MyPrivateRout from './components/MyPrivateRout';

const App: React.FC = () => {

  const isAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated);
console.log("app" + isAuthenticated);
const dispatch = useDispatch();
useEffect(() => {
  onAuthStateChanged(auth, (user) => {
    console.log("on auth state changed" + user);
    if (user) {
      dispatch(saveUser(true));
    } else {
      dispatch(saveUser(false));
    }
    dispatch(setLoading(false));

  });
}, [dispatch]);
  return (
    <DataProvider>
    <Router>
      <Navigation />
      <Routes>
      <Route path="/transactions" element={<MyPrivateRout component={Transactions} />} />
      <Route path="/portfolio" element={<MyPrivateRout component={Portfolio} />} />
      <Route path="/basicinfo" element={<MyPrivateRout component={BasicInfo} />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/login"  element={isAuthenticated ?   <Navigate to="/portfolio" />: <Login/>}/>  
        <Route path="*" element={<MyPrivateRout component={Portfolio} />} />

      </Routes>
    </Router>
    </DataProvider>
  );
};

export default App;
