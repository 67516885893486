import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';


interface PrivateRouteProps {
  component: React.ComponentType<any>;
}

const MyPrivateRout: React.FC<PrivateRouteProps> = ({ component: Component }) => {
  const isAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated);
  const isLoading = useSelector((state: RootState) => state.user.isLoading);

  if (isLoading) {
    return <div>Loading...</div>; // Or a spinner or any loading indicator
  }

  return isAuthenticated ? <Component /> : <Navigate to="/login" />;
};

export default MyPrivateRout;
