import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { BasicInfoModel, Currency, DownloaderType, getCurrencyOptions, getDownloaderTypeOptions } from '../pages/Models';


interface BasicInfoFormProps {
  open: boolean;
  onClose: () => void;
  onSave: (basicInfo: BasicInfoModel) => void;
  initialData?: BasicInfoModel;  // Make initialData optional
}

const BasicInfoForm: React.FC<BasicInfoFormProps> = ({ open, onClose, onSave, initialData }) => {
  const [basicInfo, setBasicInfo] = useState<BasicInfoModel>({  Name: '', DownloadIdentifier: '', Downloader: DownloaderType.Patria, Id:'', LastPrice: null,  LastPriceTimestamp: null, Currency: Currency.USD, PriceDate: null });

  useEffect(() => {
    if (initialData) {
      setBasicInfo(initialData);
    } else {
      setBasicInfo({  Name: '', DownloadIdentifier: '', Downloader: DownloaderType.Patria , Id: '', LastPrice: null,  LastPriceTimestamp: null, Currency: Currency.USD, PriceDate:null });
    }
  }, [initialData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setBasicInfo(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleSelectChange = (event: SelectChangeEvent<DownloaderType>) => {
    const { value } = event.target;
    setBasicInfo(prevState => ({
      ...prevState,
      Downloader: value as DownloaderType
    }));
  };


  const handleCurrencySelectChange = (event: SelectChangeEvent<Currency>) => {
    const { value } = event.target;
    setBasicInfo(prevState => ({
      ...prevState,
      Currency: value as Currency
    }));
  };

  const handleSave = () => {
    onSave(basicInfo);
    onClose();
  };
  const options = getDownloaderTypeOptions();
  const currencies = getCurrencyOptions();


  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{initialData ? 'Edit Basic Info' : 'Add Basic Info'}</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Name"
          name="Name"
          fullWidth
          value={basicInfo.Name}
          onChange={handleChange}
        />
         <TextField
          margin="dense"
          label="Download identifier"
          name="DownloadIdentifier"
          fullWidth
          value={basicInfo.DownloadIdentifier}
          onChange={handleChange}
        />
          <FormControl fullWidth>
      <InputLabel className='select' id="downloader-type-label">Downloader Type</InputLabel>
      <Select
className='select'
        labelId="downloader-type-label"
        id="downloader-type"
        value={basicInfo.Downloader}
        label="Downloader Type"
        onChange={handleSelectChange}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      </FormControl>
      <FormControl fullWidth>
      <InputLabel className='select' id="currency-type-label">Currency</InputLabel>
      <Select
className='select'
        labelId="currency-type-label"
        id="currency-type"
        value={basicInfo.Currency}
        label="Currency"
        onChange={handleCurrencySelectChange}
      >
        {currencies.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
        
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        <Button onClick={handleSave} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default BasicInfoForm;
