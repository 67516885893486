import axios from 'axios';

const api = axios.create({
  baseURL: 'https://stockportfolioapi.azurewebsites.net/api',
 //baseURL: ' http://localhost:7071/api',

  headers: {
  'x-functions-key': '67APczOtA8kShqx3oMS_UrzvjRhg8nWbQqPXX2xPqZxXAzFuo4pqBg==' 
   }});

export const getTransactions = async () => {
  const response = await api.get('/GetTransactions');
  return response.data;
};

export const upsertTransaction = async (transaction: any) => {
  console.log(transaction);
  const response = await api.post('/UpsertTransaction', transaction);
  return response.data;
};

export const deleteTransaction = async (id: string) => {
  const response = await api.post('/DeleteTransaction', { id });
  return response.data;
};

export const getBasicInfo = async () => {
  const response = await api.get('/GetBasicInfo');
  return response.data;
};

export const addBasicInfo = async (basicInfo: any) => {
  const response = await api.post('/AddBasicInfo', basicInfo);
  return response.data;
};

export const deleteBasicInfo = async (id: string) => {
  const response = await api.post('/DeleteBasicInfo', { id });
  return response.data;
};

export const getPortfolio = async () => {
  const response = await api.get('/GetPortfolio');
  return response.data;
};
