import React, { useState, useEffect, useContext } from 'react';
import { Container, Button, IconButton } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import { getBasicInfo, addBasicInfo, deleteBasicInfo } from '../api';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import BasicInfoForm from '../components/BasicInfoForm';
import { BasicInfoModel, DownloaderType, getCurrencyTypeLabel, getDownloaderTypeLabel } from './Models';
import '../App.css';
import { StoreContext } from '../state/StockContext';
import { format } from 'date-fns';

const BasicInfo: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const storeContext = useContext(StoreContext);
  var data = storeContext?.data ?? [];
  const [currentInfo, setCurrentInfo] = useState<BasicInfoModel | undefined>(undefined);



  const handleAddClick = () => {
    setCurrentInfo(undefined);
    setOpen(true);
  };

  const handleEditClick = (info: BasicInfoModel) => {
    setCurrentInfo(info);
    setOpen(true);
  };

  const handleDeleteClick = async (id: string) => {
    await deleteBasicInfo(id);
    storeContext?.refetchData();
  };

  const handleSave = async (info: BasicInfoModel) => {
    await addBasicInfo(info);
    setOpen(false);
    storeContext?.refetchData();
  };

  const columns: GridColDef[] = [
    { field: 'Name', headerName: 'Name', width: 300 },
      { field: 'LastPrice', headerName: 'Last price', width: 200 },  
      {
        field: 'Currency',
        headerName: 'Currency',
    
        width: 160,
        valueGetter: (value, row) => `${getCurrencyTypeLabel(value)}`,
      },

    { field: 'LastPriceTimestamp', headerName: 'Price download', width: 200,  
      valueGetter: (value: Date) => {
       return (value != null ? format(value, 'dd.MM.yyyy HH:mm') : '')
      }},

      { field: 'PriceDate', headerName: 'Price date', width: 200,  
        valueGetter: (value: Date) => {
         return (value != null ? format(value, 'dd.MM.yyyy') : '')
        }},
        {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleEditClick(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDeleteClick(params.row.Id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <Container>
      <Button variant="contained" color="primary" className="button" onClick={handleAddClick}>Add Basic Info</Button>
      <div style={{ width: '100%' }}>
        <DataGrid
        loading={loading}
         getRowId={(row : any) => row.Name} 
          rows={data} 
          columns={columns} 
          slots={{ toolbar: GridToolbar }} 
         

        />
      </div>
      <BasicInfoForm 
        open={open} 
        onClose={() => setOpen(false)} 
        onSave={handleSave} 
        initialData={currentInfo} 
      />
    </Container>
  );
};

export default BasicInfo;
