// Models.ts
export interface BasicInfoModel {
    Id: string;
    Name: string;
    DownloadIdentifier: string;
    Downloader: DownloaderType;
    LastPrice: number | null;
    LastPriceTimestamp: Date | null;
    Currency: Currency;
    PriceDate: Date | null;
  }
  
  export interface TransactionModel {
    Id: string;
    Price: number;
    Position: number;
    TransactionDate: string; 
    StockId: string;
    Name: string;
    PriceInCzk: number | null;
    Broker: Broker;
  }



  // Define the main interface with an index signature
  export interface PortfolioResponse {
    [key: string]: Portfolio[];
  }
  
  export interface Portfolio {
    Id: string;
    Position: number;
    Name: string;
    Price: number;
    MarketValue: number;
    AveragePrice: number;
    Profit: number;
    PriceInCzk: number;
  }
  
  export enum DownloaderType {
    Patria = 0,
    PatriaDetail = 1,
    JT = 2,
    Kurzy=3,
    Cash=4,
    Finhub = 5
  }

  export enum Broker {
    JT =0,
    Patria = 1,
    IBKR = 2
  }
  
  
  export enum Currency {
    USD = 0, EUR = 1, CZK = 2
  }
  // Helper function to get options for dropdown
  export const getDownloaderTypeOptions = () => {
    return Object.keys(DownloaderType)
      .filter(key => !isNaN(Number(key))) // filter out non-numeric keys
      .map(key => ({
        label: DownloaderType[key as keyof typeof DownloaderType],
        value: Number(key),
      }));
  };


  export const getBrokerOptions = () => {
    return Object.keys(Broker)
      .filter(key => !isNaN(Number(key))) // filter out non-numeric keys
      .map(key => ({
        label: Broker[key as keyof typeof Broker],
        value: Number(key),
      }));
  };

  export const getCurrencyOptions = () => {
    return Object.keys(Currency)
      .filter(key => !isNaN(Number(key))) // filter out non-numeric keys
      .map(key => ({
        label: Currency[key as keyof typeof Currency],
        value: Number(key),
      }));
  };

  export const getDownloaderTypeLabel = (value: DownloaderType) => {
    return DownloaderType[value];
  };

  export const getCurrencyTypeLabel = (value: Currency) => {
    return Currency[value];
  };


  export const getBrokerTypeLabel = (value: Currency) => {
    return Broker[value];
  };