import React, { useState, useEffect, useContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { BasicInfoModel, Broker, TransactionModel, getBrokerOptions } from '../pages/Models';
import { StoreContext } from '../state/StockContext';


interface TransactionFormProps {
  open: boolean;
  onClose: () => void;
  onSave: (transaction: TransactionModel) => void;
  initialData?: TransactionModel;  // Make initialData optional
}

const TransactionForm: React.FC<TransactionFormProps> = ({ open, onClose, onSave, initialData }) => {
  const [transaction, setTransaction] = useState<TransactionModel>({ Price: 0, Position: 0, TransactionDate: '', Id: '', StockId: '', Name: '', PriceInCzk: null, Broker: Broker.IBKR});

  const storeContext = useContext(StoreContext);
  var basicInfo = storeContext?.data ?? [];  
  const [selectedBasicInfoId, setSelectedBasicInfo] = useState<string>('');
  useEffect(() => {
    if (initialData) {
      setTransaction(initialData);
      setSelectedBasicInfo(initialData.StockId);
    } else {
      setTransaction({ StockId: '', Price: 0, Position: 0, TransactionDate: '', Id: '', Name: '', PriceInCzk: null, Broker: Broker.IBKR });
      }
  }, [initialData]);

  const handlerBrokerChange = (event: SelectChangeEvent<Broker>) => {
    const { value } = event.target;
    setTransaction(prevState => ({
      ...prevState,
      Broker: value as Broker
    }));
  };
  const brokers = getBrokerOptions();


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setTransaction(prevState => ({
      ...prevState,
      [name]: name === 'price' || name === 'position' ? parseFloat(value) : value === '' ? null : value
    }));
  };

  const handleSelectChange =   (e: SelectChangeEvent<string>) => {
    const selected = e.target.value as string;
    setSelectedBasicInfo(selected);
    setTransaction(prevState => ({
      ...prevState,
      StockId: selected,
    }));
  };

  const handleSave = () => {
    console.log(transaction);
    onSave(transaction);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{initialData ? 'Edit Transaction' : 'Add Transaction'}</DialogTitle>
      <DialogContent>
      <FormControl fullWidth margin="dense">
          <InputLabel className='select' id="select-label">Name</InputLabel>
          <Select className='select'
            labelId="select-label"
            id="select"
            value={selectedBasicInfoId}
            onChange={handleSelectChange}
            fullWidth
          >
            {basicInfo.map(item => (
              <MenuItem key={item.Id} value={item.Id}>
                {item.Name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          margin="dense"
          label="Price"
          name="Price"
          type="number"
          fullWidth
          value={transaction.Price}
          onChange={handleChange}
        />
           <TextField
          margin="dense"
          label="Price inC ZK"
          name="PriceInCzk"
          type="number"
          fullWidth
          value={transaction.PriceInCzk}
          onChange={handleChange}
        />
        <TextField
        
          margin="dense"
          label="Position"
          name="Position"
          type="number"
          fullWidth
          value={transaction.Position}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Transaction Date"
          name="TransactionDate"
          type="date"
          fullWidth
          value={transaction.TransactionDate}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
            <FormControl fullWidth>
      <InputLabel className='select' id="broker-type-label">Broker</InputLabel>
      <Select
className='select'
        labelId="broker-type-label"
        id="broker-type"
        value={transaction.Broker}
        label="Broker"
        onChange={handlerBrokerChange}
      >
        {brokers.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        <Button onClick={handleSave} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransactionForm;
