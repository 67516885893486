import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { BasicInfoModel } from '../pages/Models';
import { getBasicInfo } from '../api';

interface DataContextType {
  data: BasicInfoModel[]; // You can replace 'any' with your specific data type
  refetchData: () => void;
}
// Create a Context
const StoreContext = createContext<DataContextType | null>(null);
interface DataProviderProps {
  children: ReactNode;

}
const DataProvider: React.FC<DataProviderProps> = ({ children }) => {
  const [data, setData] = useState<BasicInfoModel[]>([]);

  // Fetch data on initial load
  const fetchBasicInfo = async () => {
    const data = await getBasicInfo();
    console.log(data);
    setData(data);
  };

  useEffect(() => {
    fetchBasicInfo();
  }, []);

  return (
    <StoreContext.Provider value={{ data, refetchData: fetchBasicInfo }}>
      {children}
    </StoreContext.Provider>
  );
};

export { DataProvider, StoreContext };
