import React, { useState, useEffect } from 'react';
import { Container, Button, IconButton } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { getTransactions, upsertTransaction, deleteTransaction } from '../api';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TransactionForm from '../components/TransactionForm'; 
  import { TransactionModel, getBrokerTypeLabel } from './Models';
const Transactions: React.FC = () => {
  const [transactions, setTransactions] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [currentTransaction, setCurrentTransaction] = useState<TransactionModel | undefined>(undefined);

  const fetchTransactions = async () => {
    setLoading(true)
    const data = await getTransactions();
    setTransactions(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchTransactions();
    console.log(transactions);
  }, []);

  const handleAddClick = () => {
    setCurrentTransaction(undefined);
    setOpen(true);
  };

  const handleEditClick = (transaction: TransactionModel) => {
    console.log(transaction); 
    setCurrentTransaction(transaction);
    setOpen(true);
  };

  const handleDeleteClick = async (id: string) => {
    setLoading(true)

    await deleteTransaction(id);
    fetchTransactions();
    setLoading(false);

  };

  const handleSave = async (transaction: TransactionModel) => {
    setLoading(true)

    await upsertTransaction(transaction);
    setOpen(false);
    fetchTransactions();
    setLoading(false);

  };

  const columns: GridColDef[] = [
    { field: 'Name', headerName: 'Name', width: 200 },
    { field: 'TransactionDate', headerName: 'Transaction Date', width: 200 },
    {
      field: 'Broker',
      headerName: 'Broker',
  
      width: 160,
      valueGetter: (value, row) => `${getBrokerTypeLabel(value)}`,
    },
    { field: 'Price', headerName: 'Price', width: 150 },
    { field: 'PriceInCzk', headerName: 'Price in CZK', width: 150 },
    { field: 'Position', headerName: 'Position', width: 150 },

    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleEditClick(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDeleteClick(params.row.Id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <Container>
      <Button variant="contained" color="primary" onClick={handleAddClick}>Add Transaction</Button>
      <div>
        <DataGrid 
        loading={loading}
        getRowId={row=>row.Id}
          rows={transactions} 
          columns={columns} 
          slots={{ toolbar: GridToolbar }} 
        />
      </div>
      <TransactionForm 
        open={open} 
        onClose={() => setOpen(false)} 
        onSave={handleSave} 
        initialData={currentTransaction} 
      />
    </Container>
  );
};

export default Transactions;
